import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('src/views/laudiumtoday/dashboard')),
      },
      {
        exact: true,
        path: '/ads',
        component: lazy(() => import('src/views/laudiumtoday/ads/listView')),
      },
      {
        exact: true,
        path: '/ads/new',
        component: lazy(() => import('src/views/laudiumtoday/ads/createView')),
      },
      {
        exact: true,
        path: '/articles',
        component: lazy(() =>
          import('src/views/laudiumtoday/articles/listView')
        ),
      },
      {
        exact: true,
        path: '/articles/new',
        component: lazy(() =>
          import('src/views/laudiumtoday/articles/createView')
        ),
      },
      {
        exact: true,
        path: '/businesslistings',
        component: lazy(() =>
          import('src/views/laudiumtoday/businesslistings/listView')
        ),
      },
      {
        exact: true,
        path: '/businesslistings/new',
        component: lazy(() =>
          import('src/views/laudiumtoday/businesslistings/createView')
        ),
      },
      {
        exact: true,
        path: '/businesslistings/newCat',
        component: lazy(() =>
          import('src/views/laudiumtoday/businesslistings/createCatView')
        ),
      },
      {
        exact: true,
        path: '/specials',
        component: lazy(() =>
          import('src/views/laudiumtoday/specials/listView')
        ),
      },
      {
        exact: true,
        path: '/specials/new',
        component: lazy(() =>
          import('src/views/laudiumtoday/specials/createView')
        ),
      },
      {
        exact: true,
        path: '/classifieds',
        component: lazy(() =>
          import('src/views/laudiumtoday/classifieds/listView')
        ),
      },
      {
        exact: true,
        path: '/classifieds/new',
        component: lazy(() =>
          import('src/views/laudiumtoday/classifieds/createView')
        ),
      },
      {
        exact: true,
        path: '/notices',
        component: lazy(() =>
          import('src/views/laudiumtoday/notices/listView')
        ),
      },
      {
        exact: true,
        path: '/notices/new',
        component: lazy(() =>
          import('src/views/laudiumtoday/notices/createView')
        ),
      },
    ],
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

export default routes;
